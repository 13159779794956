<template>
  <section class="hero is-info has-bg-comment">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-3">
            <p class="title is-6">Follow us</p>
            <div class="columns is-multiline is-mobile">
              <div
                class="column is-narrow"
                v-for="(link, index) in social"
                :key="index"
              >
                <a
                  @click="track('social', link.icon)"
                  :href="link.url"
                  target="_blank"
                  class="icon is-medium has-text-white"
                >
                  <i :class="`fab fa-2x fa-${link.icon}`" />
                </a>
              </div>
            </div>
          </div>
          <div class="column is-3">
            <p class="title is-6">Useful links</p>
            <p v-for="(link, index) in business" :key="index">
              <router-link
                v-if="link.isRoute"
                :to="{ name: link.url }"
                @click="track('business', link.label)"
              >
                {{ link.label }}
              </router-link>
              <a
                @click="track('business', link.label)"
                target="_blank"
                v-else-if="link.url"
                class="has-text-white"
                :href="link.url"
                >{{ link.label }}</a
              >
              <a v-else class="has-text-white" @click="openContactModal">
                {{ link.label }}
              </a>
            </p>
          </div>
          <div class="column">
            <p class="title is-6">
              &copy; {{ currentYear }} Experian. All rights reserved.
            </p>
            <p class="is-size-7">
              Experian Ltd is authorised and regulated by the Financial Conduct
              Authority. Experian Ltd is registered in England and Wales under
              the company registration number 653331.
            </p>
            <p class="is-size-7">
              Registered office address: The Sir John Peace Building, Experian
              Way, NG2 Business Park, Nottingham, NG80 1ZZ.
            </p>
            <p class="is-size-7">
              Experian and Experian marks used herein are service marks or
              registered trademarks of Experian Information Solutions Inc
            </p>
            <p class="is-size-7">
              Other product and company names mentioned herein are the property
              of their respective owners
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppFooter',
  methods: {
    track(linkType, linkName) {
      const link = linkName
        .toLowerCase()
        .split(' ')
        .join('_')
      this.$mxp.track(`app_footer_${linkType}_${link}`)
    },
    openContactModal() {
      this.track('business', 'contact_us')
      this.$modal.open('contact/ContactFooter')
    }
  },
  computed: {
    currentYear: () => new Date().getFullYear(),
    business() {
      return [
        {
          url:
            'https://www.experian.co.uk/assets/automotive/data-guarantee-terms-and-conditions.pdf',
          label: 'Data Guarantee'
        },
        {
          url: 'https://www.experian.co.uk/autocheck3-privacy-policy.pdf',
          label: 'Privacy Policy'
        },
        {
          url: 'cookie-policy',
          label: 'Cookie Policy',
          isRoute: true
        },
        {
          modal: 'contact-footer',
          label: 'Contact Us'
        }
      ]
    },
    social() {
      return [
        {
          url: 'https://www.facebook.com/ExperianBusinessUK/',
          icon: 'facebook'
        },
        {
          url: 'https://twitter.com/ExperianB2B_UK',
          icon: 'twitter'
        },
        {
          url: 'https://www.youtube.com/channel/UCwze4QXMaXECaM5Y5wwC8WQ',
          icon: 'youtube'
        },
        {
          url: 'https://www.linkedin.com/company/2534/',
          icon: 'linkedin'
        }
      ]
    }
  }
}
</script>
